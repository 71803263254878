const radialChartOptions = {
  plotOptions: {
    radialBar: {
      height: 350,
      offsetY: -10,
      startAngle: 0,
      endAngle: 360,
      hollow: {
        size: '55%',
      },
      track: {
        width: 20,
        background: '#f4f2f4',
        strokeWidth: '100%',
      },
      dataLabels: {
        name: {
          offsetY: -25,
          color: '#898699',
          fontSize: '1.314rem',
          fontWeight: 400,
        },
        value: {
          formatter(val) {
            return Math.round(val * 100) / 1000
          },
          offsetY: 0,
          color: '#5e5873',
          fontSize: '3rem',
          fontWeight: 500,
        },
      },
    },
  },
  colors: ['#fbdc8a'],
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'light',
      type: 'horizontal',
      shadeIntensity: 0.5,
      gradientToColors: ['#DEB044'],
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100],
    },
  },
  stroke: {
    lineCap: 'round',
  },
  labels: [''],
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
  },
}

export { radialChartOptions as default }
